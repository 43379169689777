import * as React from 'react'
import Layout from '../components/layout'
// import { TbArrowBigRight } from "react-icons/tb"

export default function Music() {
  return (
    <Layout pageTitle="Music">
      <section className="reading">
        <article>
          <h1>Music</h1>
          <p>Paul Clifford has played the bass since the early 90s, at first with local bands in Kingston, ON, and later with groups in Victoria, BC and Toronto, ON. He studied jazz ​u​pright bass at the University of Toronto (2002-2007) with Dave Young and Jim Vivian. Paul now lives back in his hometown Kingston and plays with a variety of artists such as:</p>
          <ul className="external">
            <li>
              <a href="https://livemusickingston.ca/chrisalfanotrio/">Chris Alfano</a>
            </li>
            <li>
              <a href="https://hannahbarstow.com/">Hannah Barstow</a>
            </li>
            <li>
              <a href="http://www.davebarton.ca/">Dave Barton</a>
            </li>
            <li>
              <a href="https://cantabilechoirs.ca/">Cantabile Choirs</a>
            </li>
            <li>
              <a href="https://mikecassells.bandcamp.com/">Mike Cassells</a>
            </li>
            <li>
              <a href="https://www.gigsalad.com/cest_la_vie_trio_kingston">C'est La Vie</a>
            </li>
            <li>
              <a href="https://thegertrudes.com/">The Gertrudes</a>
            </li>
            <li>
              <a href="https://www.queensu.ca/theisabel/content/jive-ass-slippers">The Jive Ass Slippers</a>
            </li>
            <li>
              <a href="https://kyokoogoda.com/">Kyoko Ogoda</a>
            </li>
            <li>
              <a href="https://paulmorrison.bandcamp.com/">Paul Morrison</a>
            </li>
            <li>
              <a href="https://www.gregrunions.net/">Greg Runions</a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCZ_DX_H3mFrt38t_f2yZQ4Q">Chantal Thompson</a>
            </li>
            <li>
              <a href="https://joelwilliams.bandcamp.com/">Joel Williams</a>
            </li>
            <li>
              <a href="https://www.instagram.com/voiceofhannya/">Voice Of Hannya</a>
            </li>
          </ul>
        </article>
      </section>
    </Layout>
  )
}
